import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("UserResult")
export class UserResult {
    @JsonProperty("usr_id", Number)
    id: number = 0;
    @JsonProperty("usr_name", String)
    name: string = "";
    @JsonProperty("usr_age", Number)
    age: number = 0;
}