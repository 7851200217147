import { RequestBatchResult, UserQuestion } from "domain/entities/question";
import { QuestionRepository } from "domain/repositories";

export default class CompleteUseCase {
    private questionRepository: QuestionRepository;
    
    public constructor(questionRepository: QuestionRepository) {
        this.questionRepository = questionRepository
    }

    public async completeQuestions(userQuestions: UserQuestion): Promise<([RequestBatchResult, null]) | ([null, Error])> {
        return this.questionRepository.completeQuestions(userQuestions);
    }
}