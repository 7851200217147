import React, { FunctionComponent } from 'react';

import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';

import { ICustomModalProps } from './ICustomModalProps';

export const CustomModal: FunctionComponent<ICustomModalProps> = (props) => {
    return (
        <>
            <Modal
                closeOnEsc={false}
                closeOnOverlayClick={false}
                isOpen={props.isOpen}
                onClose={props.onClose}
                scrollBehavior="inside"
                size="lg"
            >
                <ModalOverlay />
                <ModalContent>
                        <ModalHeader>{props.title}</ModalHeader>
                        <ModalBody pb={6}>
                            {props.children}
                        </ModalBody>
                        <ModalFooter>
                            <Button 
                                colorScheme="blue" 
                                onClick={props.onStart}
                                isLoading={props.isButtonLoading}
                                loadingText="enviando..."
                                disabled={props.isButtonDisable}
                            >
                                {props.buttonText}
                            </Button>
                        </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}