import Base from "data/base";
import { TaskRepository } from "domain/repositories";
import { UserTask, RequestResult } from "domain/entities/task";

export class TaskApi implements TaskRepository {
    
    private static async rute(): Promise<string> {
        const res = await Base.getConfig();
        return res.PRS_API_URL + "/api/v1/tasks";
    }

    async completeTask(userTask: UserTask): Promise<[RequestResult, null] | [null, Error]> {
        try {
            const url: string = await TaskApi.rute() + "/complete";
            const [res, error] = await Base.post(url, {
                tas_id: userTask.taskId,
                usr_id: userTask.userId,
                tas_seconds: userTask.seconds,
                ust_state: userTask.state,
            }, RequestResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }
}