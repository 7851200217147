import React, { FormEvent, useEffect, useState } from 'react';

import { Box, Button, Center, Divider, Flex, FormControl, Heading, Radio, RadioGroup, Spinner, Stack, Text } from '@chakra-ui/react';
import { RiAlarmWarningLine } from 'react-icons/ri'
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser';
import { Element } from "domhandler/lib/node";
import { useGeneralInfo, useUsers } from 'presentation/view-models/hooks';
import { useLocation } from 'wouter';

const options: HTMLReactParserOptions = {
    replace: (domNode) => {
        const elem = domNode as Element;
        if (elem.name === 'div') {
            return <Box>{domToReact(elem.children, options)}</Box>;
        }
        if (elem.name === 'h1') {
            return <Center><Heading as="h2" size="2xl">{domToReact(elem.children, options)}</Heading></Center>
        }
        if (elem.name === 'h2') {
            return <Center><Heading as="h2" size="xl">{domToReact(elem.children, options)}</Heading></Center>
        }
        if (elem.name === 'h3') {
            return <Center><Heading as="h3" size="lg">{domToReact(elem.children, options)}</Heading></Center>
        }
        if (elem.name === 'h4') {
            return <Center><Heading as="h4" size="md">{domToReact(elem.children, options)}</Heading></Center>
        }
        if (elem.name === 'h5') {
            return <Center><Heading as="h5" size="sm">{domToReact(elem.children, options)}</Heading></Center>
        }
        if (elem.name === 'h6') {
            return <Center><Heading as="h6" size="xs">{domToReact(elem.children, options)}</Heading></Center>
        }
        if (elem.name === 'p') {
            return <Center><Text fontWeight="medium" color="gray.600" textAlign="center">{domToReact(elem.children, options)}</Text></Center>
        }
    }
};

export default function WelcomePage() {
    const { getTest, state, data } = useGeneralInfo();
    const { saveUser, state: userState, data: userData } = useUsers();
    const [advInicial, setAdvInicial] = useState(true);
    const [testId, setTestId] = useState(1);
    const [nombre, setNombre] = useState("");
    const [, setLocation] = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const pTestId = +(urlParams.get("te") ?? 0);
        setTestId(pTestId);
        getTest(pTestId);
    }, [getTest]);

    const _handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        saveUser({
            id: 0,
            name: nombre,
            age: 18,
        });
    }

    useEffect(() => {
        if (!userState.loading && !userState.error && userState.reqCompleted && userData) {
            const userId = userData.id;
            setLocation(`/test?te=${testId}&us=${userId}`);
        }
    }, [setLocation, testId, userData, userState.error, userState.loading, userState.reqCompleted]);

    return (
        <Flex w="full" h="100vh" align="center" justifyContent="center" bgGradient="linear(to-r, blue.500, purple.700)">
            <Box p={8} m={5} maxWidth="700px" bg="white" borderWidth={1} borderRadius={15} boxShadow="lg">
                {state.dataLoading &&
                    <Center>
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size="xl"
                        />
                    </Center>
                }
                {!state.dataLoading && state.error &&
                    "Error al recuperar test"
                }
                {!state.dataLoading && !state.error && data && advInicial &&
                    <>
                        <Box>
                            <Center><Heading as="h3" size="lg">{data.appName}</Heading></Center>
                        </Box>
                        <Divider mt={15} mb={15}/>
                        <Box>
                            <Center><RiAlarmWarningLine size={60}/></Center>
                        </Box>
                        <Box mt={15} border="1px" borderColor="gray.400" borderRadius={15} overflow="hidden">
                            <Text m={3} fontSize="md">El presente experimento es voluntario y totalmente anónimo por lo que no se le preguntará su nombre o apellido.</Text>
                            <Button
                                colorScheme="blue"
                                width="full"
                                mt={4}
                                onClick={() => setAdvInicial(false)}
                            >
                                Estoy de acuerdo y deseo continuar
                            </Button>
                        </Box>
                    </>
                }
                {!state.dataLoading && !state.error && data && !advInicial &&
                    <>
                        {parse(data.welcomeMsg, options)}
                        <Box mt={15} border="1px" borderColor="gray.400" borderRadius={15} overflow="hidden">
                            <form onSubmit={_handleSubmit}>
                                <Text m={3} fontSize="md">Seleccione un rango etario:</Text>
                                <FormControl>
                                    <RadioGroup m={3} onChange={(v) => setNombre(v)}>
                                        <Stack direction="row" flexWrap="wrap">
                                            <Radio m={1} value="18-30">30 años o menos</Radio>
                                            <Radio m={1} value="31-40">31-40 años</Radio>
                                            <Radio m={1} value="41-50">41-50 años</Radio>
                                            <Radio m={1} value="51-60">51-60 años</Radio>
                                            <Radio m={1} value="61-70">61-70 años</Radio>
                                            <Radio m={1} value="71-80">71-80 años</Radio>
                                            <Radio m={1} value="81+">81 años o más</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </FormControl>
                                <Button
                                    type="submit"
                                    colorScheme="blue"
                                    width="full"
                                    mt={4}
                                    disabled={nombre === ""}
                                    isLoading={userState.loading}
                                    loadingText="Espere un momento..."
                                >
                                    Comenzar prueba
                                </Button>
                            </form>
                        </Box>
                    </>
                }
            </Box>
        </Flex>
    );
}