import Base from "data/base";
import { GeneralInfoResult } from "domain/entities/general-info";
import { GeneralInfoRepository } from "domain/repositories";

export class GeneralInfoApi implements GeneralInfoRepository {
    
    private static async rute(): Promise<string> {
        const res = await Base.getConfig();
        return res.PRS_API_URL + "/api/v1/tests";
    }

    async getTest(id: number): Promise<[GeneralInfoResult, null] | [null, Error]> {
        try {
            const url: string = await GeneralInfoApi.rute() + "/" + id;

            const [res, error] = await Base.get(url, GeneralInfoResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

}