import React from "react";

import { Redirect } from 'wouter';

export const DefaultRoute: React.FC<{
    defaultPath: string;
}> = (props) => {
    return (
        <Redirect to={props.defaultPath} />
    );
};