import { useCallback, useState } from "react";

import { UserApi } from "data";
import { User, UserResult } from "domain/entities/user";
import CreateUseCase from "domain/use-cases/user/CreateUseCase";
import { IRequestResult2, RequestType } from "presentation/utils/interfaces/IRequestResult2";
import GetOneUseCase from "domain/use-cases/user/GetOneUseCase";

// Retorna una promesa para obtener de la API un usuario registrado por ID.
const _getUser = (userId: number) => {
    const userRepository = new UserApi();
    const getOneUseCase = new GetOneUseCase(userRepository);

    return getOneUseCase.getOneUsers(userId);
};

// Retorna una promesa para agregar un usuario desde la API.
const _createUser = (user: User) => {
    const userRepository = new UserApi();
    const createUseCase = new CreateUseCase(userRepository);

    return createUseCase.createUser(user);
};

// Hook usado para gestionar el CRUD de usuarios.
export const useUsers = () => {
    // Estado del hook.
    const [state, setState] = useState<IRequestResult2>({
        dataLoading: false,
        loading: false,
        reqType: undefined,
        reqCompleted: undefined,
        error: undefined,
    });
    const [data, setData] = useState<UserResult>();

    const isUpdateStateOk = useCallback((error?: Error | null): boolean => {
        if (error) {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: error.message }));
            return false;
        } else {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: undefined }));
            return true;
        }
    }, []);

    // Obtiene de la API todos los usuarios registrados.
    const getUser = useCallback((userId: number) => {
        setState({ dataLoading: true, loading: false });
        _getUser(userId).then(([res, error]) => {
            setState({
                reqType: RequestType.GET,
                dataLoading: false,
                loading: false,
                error: error?.message,
            });
            setData(res ?? undefined);
        });
    }, []);

    // Guarda un usuario desde API
    const saveUser = useCallback((user: UserResult) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));
        _createUser(user).then(([res, error]) => {
            if (isUpdateStateOk(error)) {
                user.id = res?.data?.id ?? 0;
                setData(user);
            }
        });
    }, [isUpdateStateOk]);

    return { getUser, saveUser, state, data };
};