import * as React from "react";

import { ChakraProvider, theme } from "@chakra-ui/react";
import { Route, Switch } from "wouter";

import { DefaultRoute } from "presentation/components";
import WelcomePage from "presentation/views/Welcome/WelcomePage";
import HomePage from "presentation/views/Home/HomePage";
import EndPage from "presentation/views/End/EndPage";

export const App = () => (
    <ChakraProvider theme={theme}>
        <Switch>
            <Route path="/welcome" component={WelcomePage} />
            <Route path="/test" component={HomePage} />
            <Route path="/end" component={EndPage} />
            <DefaultRoute defaultPath="/welcome" />
        </Switch>
    </ChakraProvider>
);
