import { User, RequestResult } from "domain/entities/user";
import { UserRepository } from "domain/repositories";

export default class CreateUseCase {
    private userRepository: UserRepository;
    
    public constructor(userRepository: UserRepository) {
        this.userRepository = userRepository
    }

    public async createUser(user: User): Promise<([RequestResult, null]) | ([null, Error])> {
        return this.userRepository.createUser(user);
    }
}