import { QuestionOptionResult } from "domain/entities/question-option";
import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("QuestionResult")
export class QuestionResult {
    @JsonProperty("que_id", Number)
    id: number = 0;
    @JsonProperty("tas_id", Number)
    taskId: number = 0;
    @JsonProperty("que_question", String)
    question: string = "";
    @JsonProperty("question_options", [QuestionOptionResult])
    questionOptions: QuestionOptionResult[] = [];
}