import { UserResult, User, RequestResult } from "domain/entities/user";
import { UserRepository } from "domain/repositories";
import Base from "data/base";

export class UserApi implements UserRepository {
    
    private static async rute(): Promise<string> {
        const res = await Base.getConfig();
        return res.PRS_API_URL + "/api/v1/users";
    }

    async getUser(id: number): Promise<[UserResult, null] | [null, Error]> {
        try {
            const url: string = await UserApi.rute() + "/" + id;
                
            const [res, error] = await Base.get(url, UserResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];
    
            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }

    async createUser(user: User): Promise<[RequestResult, null] | [null, Error]> {
        try {
            const url: string = await UserApi.rute() + "/";
            const [res, error] = await Base.post(url, {
                usr_name: user.name,
                usr_age: user.age,
            }, RequestResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }
}