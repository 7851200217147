import { GeneralInfoResult } from "domain/entities/general-info";
import { GeneralInfoRepository } from "domain/repositories";

export default class GetOneUseCase {
    private generalInfoRepository: GeneralInfoRepository;
    
    public constructor(generalInfoRepository: GeneralInfoRepository) {
        this.generalInfoRepository = generalInfoRepository
    }

    public async getOneTest(id: number): Promise<[GeneralInfoResult, null] | [null, Error]> {
        return this.generalInfoRepository.getTest(id);
    }
}