import React, { useEffect, useState } from 'react';

import { Box, Button, Center, Flex, Heading, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Radio, RadioGroup, Spacer, Spinner, Stack, Text } from '@chakra-ui/react';
import { v4 as uuid_v4 } from 'uuid';
import { FiHelpCircle } from 'react-icons/fi';

import { useGeneralInfo } from 'presentation/view-models/hooks';
import { CustomModal } from 'presentation/components';
import { useLocation } from 'wouter';
import { useTasks } from 'presentation/view-models/hooks/useTask';


export default function HomePage() {
    const { getTest, state, data } = useGeneralInfo();
    const [cuestionario, setCuestionario] = useState<{ userId: number, questionId: number, questionOptionId: number }[]>([]);
    const [cuestionarioCompleto, setCuestionarioCompleto] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState(true)
    const [isOpenQuestions, setIsOpenQuestions] = useState(false)
    const [testId, setTestId] = useState(1);
    const [userId, setUserId] = useState(1);
    const [step, setStep] = useState(0);
    const [timer, setTimer] = useState(new Date());
    const [seconds, setSeconds] = useState(0);
    const [saltarTest, setSaltarTest] = useState<boolean>(false);
    const [, setLocation] = useLocation();

    const { saveCompleteTask, state: taskState } = useTasks();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const pTestId = +(urlParams.get("te") ?? 0);
        const pUserId = +(urlParams.get("us") ?? 0);
        setTestId(pTestId);
        setUserId(pUserId);
        getTest(pTestId);
    }, [getTest]);

    const _startTask = () => {
        setTimer(new Date());
        setIsOpen(false);
    }

    const _onNextTask = () => {
        if (data) {
            saveCompleteTask({
                taskId: data.tasks[step].id,
                userId: userId,
                seconds: seconds,
                state: saltarTest ? "saltado" : "completado",
            }, {
                questions: cuestionario,
            });
        }
        setSaltarTest(false);
    };

    useEffect(() => {
        if (!taskState.loading && !taskState.error && taskState.reqCompleted) {
            setIsOpenQuestions(false);
            setCuestionario([]);
            if ((data?.tasks.length ?? 0) > step + 1) {
                setStep(prev => prev + 1);
                setIsOpen(true);
            } else {
                setLocation(`/end?te=${testId}`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskState.error, taskState.loading, taskState.reqCompleted]);

    const _onCompleteTask = () => {
        const endDate = new Date();
        const startDate = timer;
        const seconds = +((endDate.getTime() - startDate.getTime()) / 1000).toFixed(0);

        setSeconds(seconds);
        setIsOpenQuestions(true);
    };

    const _onSaltarTask = () => {
        const endDate = new Date();
        const startDate = timer;
        const seconds = +((endDate.getTime() - startDate.getTime()) / 1000).toFixed(0);

        setSaltarTest(true);
        setSeconds(seconds);
        setIsOpenQuestions(true);
    };

    const _getValorPregunta = (questionId: number) => {
        const value = cuestionario.find((c) => c.questionId === questionId);
        return value?.questionOptionId;
    };

    const _updateCuestionario = (questionId: number, optionId: number) => {
        const index = cuestionario.findIndex((c) => c.questionId === questionId);
        const nArr = [...cuestionario];
        if (index === -1) {
            nArr.push({ userId: userId, questionId: questionId, questionOptionId: optionId });
        } else {
            nArr[index] = { userId: userId, questionId: questionId, questionOptionId: optionId };
        }
        if ((data?.tasks[step].questions.length ?? 0) <= cuestionario.length + 1) {
            setCuestionarioCompleto(true);
        }
        setCuestionario(nArr);
        console.log(nArr);
    };

    return (
        <>
            <Flex w="full" h="90vh">
                {!state.dataLoading && !state.error && data &&
                    <iframe width="100%" height="100%" title="pag-test" src={data.tasks[step].startUrl} />
                }
            </Flex>
            <Box w="100%" h="10vh" border="1px" borderColor="gray.600" alignItems="center">
                {!state.dataLoading && !state.error && data &&
                    <Flex alignItems="center">
                        <Box ml={3} display={{ base: 'block', md: 'none' }}>
                            <Popover>
                                <PopoverTrigger>
                                    <Button leftIcon={<FiHelpCircle />} colorScheme="teal" variant="solid">
                                        Ayuda
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverHeader>Tarea</PopoverHeader>
                                    <PopoverBody>
                                        <Box ml={3}>
                                            <Box>
                                                <Text fontWeight="medium" color="gray.600" textAlign="center">
                                                    {data?.tasks[step].scenario}
                                                </Text>
                                            </Box>
                                            <Box>
                                                <Text fontWeight="medium" color="gray.600" textAlign="center">
                                                    {data?.tasks[step].description}
                                                </Text>
                                            </Box>
                                        </Box>
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>

                        </Box>
                        <Box ml={3} display={{ base: 'none', md: 'block' }}>
                            <Box>
                                <Text fontWeight="medium" color="gray.600" textAlign="center">
                                    {data?.tasks[step].scenario}
                                </Text>
                            </Box>
                            <Box>
                                <Text fontWeight="medium" color="gray.600" textAlign="center">
                                    {data?.tasks[step].description}
                                </Text>
                            </Box>
                        </Box>
                        <Spacer />
                        <Box m={2}>
                            <Button
                                colorScheme="blue"
                                onClick={_onCompleteTask}
                            >
                                Completar tarea
                            </Button>
                        </Box>
                        <Box m={2}>
                            <Button onClick={_onSaltarTask}>Saltar tarea</Button>
                        </Box>
                    </Flex>
                }
            </Box>
            <CustomModal
                title="Tarea"
                buttonText="Comenzar"
                isOpen={isOpen}
                isButtonLoading={false}
                isButtonDisable={false}
                onClose={() => setIsOpen(false)}
                onStart={_startTask}
            >
                {state.dataLoading &&
                    <Center>
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size="xl"
                        />
                    </Center>
                }
                {!state.dataLoading && state.error &&
                    <Center>Error al recuperar test</Center>
                }
                {!state.dataLoading &&
                    <Box>
                        <Box>
                            <Center><Heading as="h4" size="md">{data?.tasks[step].title}</Heading></Center>
                        </Box>
                        <Box mt="10px" mb="20px">
                            <Text fontWeight="medium" color="gray.600" textAlign="center">{data?.tasks[step].scenario}</Text>
                        </Box>
                        <Box border="1px" borderRadius={15} p="10px">
                            <Text fontWeight="medium" color="gray.600" textAlign="center">{data?.tasks[step].description}</Text>
                        </Box>
                    </Box>
                }
            </CustomModal>
            <CustomModal
                title="Cuestionario"
                buttonText="Guardar"
                isOpen={isOpenQuestions}
                isButtonLoading={taskState.loading}
                isButtonDisable={!cuestionarioCompleto}
                onClose={() => setIsOpenQuestions(false)}
                onStart={_onNextTask}
            >
                <Box>
                    {data?.tasks[step].questions.map(q => (
                        <Box key={uuid_v4()}>
                            <Box mb={2} mt={4}>
                                <Heading as="h5" size="sm">{q.question}</Heading>
                            </Box>
                            <Box>
                                <RadioGroup value={_getValorPregunta(q.id)} onChange={(v) => _updateCuestionario(q.id, +v)}>
                                    <Stack direction="row">
                                        {q.questionOptions.map(op => (
                                            <Radio key={uuid_v4()} value={op.id}>{op.name}</Radio>
                                        ))}
                                    </Stack>
                                </RadioGroup>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </CustomModal>
        </>
    );
}