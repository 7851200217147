import { RequestResult, UserTask } from "domain/entities/task";
import { TaskRepository } from "domain/repositories";

export default class CompleteUseCase {
    private taskRepository: TaskRepository;
    
    public constructor(taskRepository: TaskRepository) {
        this.taskRepository = taskRepository
    }

    public async completeTask(userTask: UserTask): Promise<([RequestResult, null]) | ([null, Error])> {
        return this.taskRepository.completeTask(userTask);
    }
}