import { TaskResult } from "domain/entities/task";
import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("GeneralInfoResult")
export class GeneralInfoResult {
    @JsonProperty("gen_id", Number)
    id: number = 0;
    @JsonProperty("gen_app_name", String)
    appName: string = "";
    @JsonProperty("gen_welcome_msg", String)
    welcomeMsg: string = "";
    @JsonProperty("gen_end_msg", String)
    endMsg: string = "";
    @JsonProperty("tasks", [TaskResult])
    tasks: TaskResult[] = [];
}