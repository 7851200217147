import { UserResult } from "domain/entities/user";
import { UserRepository } from "domain/repositories";

export default class GetOneUseCase {
    private userRepository: UserRepository;
    
    public constructor(userRepository: UserRepository) {
        this.userRepository = userRepository
    }

    public async getOneUsers(id: number): Promise<([UserResult, null]) | ([null, Error])> {
        return this.userRepository.getUser(id);
    }
}