import React, { useEffect } from 'react';

import { Box, Button, Center, Flex, Heading, Spinner, Text } from '@chakra-ui/react';
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser';
import { Element } from "domhandler/lib/node";
import { useGeneralInfo } from 'presentation/view-models/hooks';

const options: HTMLReactParserOptions = {
    replace: (domNode) => {
        const elem = domNode as Element;
        if (elem.name === 'div') {
            return <Box>{domToReact(elem.children, options)}</Box>;
        }
        if (elem.name === 'h1') {
            return <Center><Heading as="h2" size="2xl">{domToReact(elem.children, options)}</Heading></Center>
        }
        if (elem.name === 'h2') {
            return <Center><Heading as="h2" size="xl">{domToReact(elem.children, options)}</Heading></Center>
        }
        if (elem.name === 'h3') {
            return <Center><Heading as="h3" size="lg">{domToReact(elem.children, options)}</Heading></Center>
        }
        if (elem.name === 'h4') {
            return <Center><Heading as="h4" size="md">{domToReact(elem.children, options)}</Heading></Center>
        }
        if (elem.name === 'h5') {
            return <Center><Heading as="h5" size="sm">{domToReact(elem.children, options)}</Heading></Center>
        }
        if (elem.name === 'h6') {
            return <Center><Heading as="h6" size="xs">{domToReact(elem.children, options)}</Heading></Center>
        }
        if (elem.name === 'p') {
            return <Center><Text fontWeight="medium" color="gray.600" textAlign="center">{domToReact(elem.children, options)}</Text></Center>
        }
    }
};

export default function EndPage() {
    const { getTest, state, data } = useGeneralInfo();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const pTestId = +(urlParams.get("te") ?? 0);
        getTest(pTestId);
    }, [getTest]);

    const _onClose = () => {
        window.open("about:blank", "_self");
        window.close();
    };

    return (
        <Flex w="full" h="100vh" align="center" justifyContent="center" bgGradient="linear(to-r, blue.500, purple.700)">
            <Box p={8} m={5} maxWidth="700px" bg="white" borderWidth={1} borderRadius={15} boxShadow="lg">
                {state.dataLoading &&
                    <Center>
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size="xl"
                        />
                    </Center>
                }
                {!state.dataLoading && state.error &&
                    "Error al recuperar test"
                }
                {!state.dataLoading && !state.error && data &&
                    <Box>
                        <Box>
                            {parse(data.endMsg, options)}
                        </Box>
                        <Box>
                            <Button
                                colorScheme="blue"
                                width="full"
                                mt={4}
                                onClick={_onClose}
                            >
                                Cerrar
                            </Button>
                        </Box>
                    </Box>
                }
            </Box>
        </Flex>
    );
}