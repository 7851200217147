import { useCallback, useState } from "react";

import { QuestionApi, TaskApi } from "data";
import { UserTask, TaskResult } from "domain/entities/task";
import CompleteUseCase from "domain/use-cases/task/CompleteUseCase";
import CompleteUseCase1 from "domain/use-cases/question/CompleteUseCase";
import { IRequestResult2, RequestType } from "presentation/utils/interfaces/IRequestResult2";
import { UserQuestion } from "domain/entities/question";


// Retorna una promesa para agregar un usuario desde la API.
const _completeTask = (userTask: UserTask) => {
    const taskRepository = new TaskApi();
    const completeUseCase = new CompleteUseCase(taskRepository);

    return completeUseCase.completeTask(userTask);
};

// Retorna una promesa para agregar un usuario desde la API.
const _completeQuestion = (userQuestion: UserQuestion) => {
    const questionApi = new QuestionApi();
    const completeUseCase = new CompleteUseCase1(questionApi);

    return completeUseCase.completeQuestions(userQuestion);
};

// Hook usado para gestionar el CRUD de usuarios.
export const useTasks = () => {
    // Estado del hook.
    const [state, setState] = useState<IRequestResult2>({
        dataLoading: false,
        loading: false,
        reqType: undefined,
        reqCompleted: undefined,
        error: undefined,
    });
    const [data, setData] = useState<TaskResult>();

    const isUpdateStateOk = useCallback((error?: Error | null): boolean => {
        if (error) {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: error.message }));
            return false;
        } else {
            setState((prev) => ({ ...prev, loading: false, reqCompleted: true, error: undefined }));
            return true;
        }
    }, []);

    // Guarda un usuario tarea desde API
    const saveCompleteTask = useCallback((userTask: UserTask, userQuestions: UserQuestion) => {
        setState((prev) => ({ ...prev, reqType: RequestType.POST, loading: true, reqCompleted: false }));
        _completeTask(userTask).then(([res, error]) => {
            if (!error) {
                _completeQuestion(userQuestions).then(([res, error]) => {
                    isUpdateStateOk(error);
                });
            } else {
                isUpdateStateOk(error);
            }
        });
    }, [isUpdateStateOk]);

    return { saveCompleteTask, state, data };
};