import { QuestionResult } from "domain/entities/question";
import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("TaskResult")
export class TaskResult {
    @JsonProperty("tas_id", Number)
    id: number = 0;
    @JsonProperty("gen_id", Number)
    generalInfoId: number = 0;
    @JsonProperty("tas_internal_name", String)
    internalName: string = "";
    @JsonProperty("tas_title", String)
    title: string = "";
    @JsonProperty("tas_scenario", String)
    scenario: string = "";
    @JsonProperty("tas_description", String)
    description: string = "";
    @JsonProperty("tas_start_url", String)
    startUrl: string = "";
    @JsonProperty("questions", [QuestionResult])
    questions: QuestionResult[] = [];
}