import Base from "data/base";
import { QuestionRepository } from "domain/repositories";
import { UserQuestion, RequestBatchResult } from "domain/entities/question";

export class QuestionApi implements QuestionRepository {
    
    private static async rute(): Promise<string> {
        const res = await Base.getConfig();
        return res.PRS_API_URL + "/api/v1/questions";
    }

    async completeQuestions(userQuestions: UserQuestion): Promise<[null, Error] | [RequestBatchResult, null]> {
        try {
            const url: string = await QuestionApi.rute() + "/complete";
            const [res, error] = await Base.post(url, {
                question_results: userQuestions.questions.map(q => ({
                    usr_id: q.userId,
                    que_id: q.questionId,
                    quo_id: q.questionOptionId,
                })),
            }, RequestBatchResult);
            if (res === null && error !== null) return [null, error];
            if (res !== null) return [res, null];

            return [null, new Error("Error desconocido")]
        } catch (e) {
            return [null, e];
        }
    }
}