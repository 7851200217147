import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("QuestionOptionResult")
export class QuestionOptionResult {
    @JsonProperty("quo_id", Number)
    id: number = 0;
    @JsonProperty("que_id", Number)
    questionId: number = 0;
    @JsonProperty("quo_name", String)
    name: string = "";
    @JsonProperty("quo_value", String)
    value: string = "";
}