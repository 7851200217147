import { useCallback, useState } from "react";

import { GeneralInfoApi } from "data";
import { IRequestResult2, RequestType } from "presentation/utils/interfaces/IRequestResult2";
import GetOneUseCase from "domain/use-cases/general-info/GetOneUseCase";
import { GeneralInfoResult } from "domain/entities/general-info";

// Retorna una promesa para obtener de la API un test.
const _getGeneralInfo = (id: number) => {
    const generalInfoRepository = new GeneralInfoApi();
    const getOneUseCase = new GetOneUseCase(generalInfoRepository);

    return getOneUseCase.getOneTest(id);
};

// Hook usado para gestionar el CRUD de tests.
export const useGeneralInfo = () => {
    // Estado del hook.
    const [state, setState] = useState<IRequestResult2>({
        dataLoading: false,
        loading: false,
        reqType: undefined,
        reqCompleted: undefined,
        error: undefined,
    });
    const [data, setData] = useState<GeneralInfoResult>();

    // Obtiene de la API un test registrado.
    const getTest = useCallback((id: number) => {
        setState({ dataLoading: true, loading: false });
        _getGeneralInfo(id).then(([res, error]) => {
            setState({
                reqType: RequestType.GET,
                dataLoading: false,
                loading: false,
                error: error?.message,
            });
            setData(res ?? undefined);
        });
    }, []);

    return { getTest, state, data };
};